import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0,2,4,5];

export const dictionary = {
		"/(brand)/(all-platforms)": [6,[2,3]],
		"/(brand)/(instagram-focused)/instagram": [~11,[2,4]],
		"/(brand)/(instagram-focused)/instagram/diagnostic": [~12,[2,4]],
		"/(brand)/(instagram-focused)/instagram/diagnostic/[id]": [~13,[2,4]],
		"/(brand)/(instagram-focused)/instagram/offer": [14,[2,4]],
		"/(brand)/(instagram-focused)/instagram/offer/complete": [~15,[2,4]],
		"/(brand)/(all-platforms)/offer": [~7,[2,3]],
		"/(brand)/(all-platforms)/offer/after-signup": [~8,[2,3]],
		"/(brand)/(all-platforms)/offer/complete": [~9,[2,3]],
		"/(brand)/(all-platforms)/offer/signup": [~10,[2,3]],
		"/rewards/login": [18,[5]],
		"/rewards/onboarding": [~19,[5]],
		"/rewards/signup": [20,[5]],
		"/rewards/verify": [21,[5]],
		"/(brand)/(talk-to-sales-form)/sales": [16,[2]],
		"/[...path]": [~17]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';